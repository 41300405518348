import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7559d2b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "handler_box" }
const _hoisted_2 = {
  key: 0,
  class: "btn_handler"
}
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { class: "tip" }
const _hoisted_5 = { class: "sign_box" }
const _hoisted_6 = {
  key: 1,
  class: "btn_handler"
}
const _hoisted_7 = ["innerHTML"]

import { $apiRevampState,$apiOrderPay,$apiOrderBalancePay } from '@/api/modules/newOrder.js'
import OfflinePay from './OfflinePay.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import { CountDown } from 'vant';
import WxPayCode from '@/components/WxPayCode.vue'
import PayWay from '@/view/deal/deposit/PayWay.vue';
import { useStore } from "vuex";

export default {
  props: {
  orderInfo:Object
},
  emits: ['refresh'],
  setup(__props, { emit: emits }) {

const props = __props

const store = useStore();

let offlineShow = ref(false)
const token = localStorage.getItem('token')

// 获取余额信息
token&&store.dispatch('getBlance', token)
const info = reactive({
  payMoney:'',//支付金额
  showPayWayPopup:false,//选择支付方式弹窗
  payType:'',//选择支付的类型
  confirmLoading:false,//选择支付方式的loading
  wxCodeLink:'',//微信二维码链接
  showWxCode:false,//展示微信二维码弹窗
  payState:1,//支付状态，目前用于区分支付定金和支付尾款
})
// 订单操作提示
const orderHandlerTip = (type)=>{
  info.payState = type
  // 支付定金
  if(type === 0){
    info.payMoney = props.orderInfo.deposit
    return info.showPayWayPopup = true
  }
  // 支付货款
  if(type === 1){
    info.payMoney = props.orderInfo.orders_money
    return info.showPayWayPopup = true
  }
  let msg = ''
  if(type === 1)msg = '已收到货款'
  if(type === 4)msg = '拒买'
  if(type === 9 || type === 3)msg = '签收'
  if(type === 6)msg = '拒卖'
  if(type === 2 || type === 9&&props.orderInfo.type===5)msg = getPayBtnText()
  ElMessageBox.confirm(`您确认${msg}吗?${[4,6].includes(type)?`该笔订单的${props.orderInfo.deposit}元定金将赔付给对方哦。` : ''}`, '提示', {confirmButtonText: '确认',cancelButtonText:'取消'}).then((ok)=>{
    orderHandler(type)
  })
}
// 订单操作
const orderHandler = async(type)=>{
  const { status, data, msg } = await $apiRevampState({ token:localStorage.getItem('token'), state:type, orderId:props.orderInfo.ordersId, });
  if(status){
    ElMessage.success(data)
    emits('refresh')
  }else{
    ElMessage.warning(msg)
  }
}
// 获取支付类型支付
const getPayType = async(type)=>{
  info.payType = type
  info.confirmLoading = true
  // 尾款支付
  if(info.payState === 1){
    // 线下支付
    if(info.payType === 8){return offlineShow.value = true}
    // 余额支付
    const {data,status,msg} = await $apiOrderBalancePay({token:localStorage.getItem('token'),ordersId:props.orderInfo.ordersId,type:1})
    status?paySuccessHandler(data):ElMessage.error({message:msg})
  }else{
    // 付定金
    const ordersId = props.orderInfo.user_state == 2?props.orderInfo?.contract : props.orderInfo?.note
    const {data,status,msg} = await $apiOrderPay({ordersId,type:info.payType,returnUrl:window.location.href})
    status?paySuccessHandler(data):ElMessage.error({message:msg})
  }
  info.confirmLoading = false
}

const zfbForm = ref()//支付宝
// 选完支付成功的操作
const paySuccessHandler = (data)=>{
  // 支付宝支付
  if(info.payType === 6){
    zfbForm.value = data
    setTimeout(()=>{
      document.forms['alipaysubmit'].submit();
      info.confirmLoading = false
    })
    return 
  }
  // 微信支付弹二维码
  if(info.payType == 3){
    info.payMoney = data.money
    info.wxCodeLink = data.url.code_url
    info.showWxCode = true
  }else{
    info.showPayWayPopup = false
    // 余额支付完成关闭弹窗
    ElMessageBox.alert('支付成功，可在列表中查看哦~', '支付', {confirmButtonText: '好的'})
    emits('refresh')
  }
}

// 获取倒计时
const getCountDown = (dueDays)=>{
  const date = new Date().getTime()
  const created_at = Date.parse(props.orderInfo.created_at)+(dueDays || 1)*1000*60*60*24
  return (created_at - date)<=0 ? 0 : created_at - date
}
// 显示倒计时
const showCountDown = ()=>{
  const info = props.orderInfo
  if(info.user_state == 2){
    if([8].includes(info.pay_state)&&info.payment==1) return true
  }else{
    if([8].includes(info.pay_state)&&info.payment==2) return true
  }
}
// 过滤倒计时的信息
const filterTip = (type) => {
  // user_state:2买家，1：卖家
  const info = props.orderInfo
  if([8].includes(type)){
    return info.user_state === '2'?'内完成付款':'确认发货'
  }
}

// 确认货款/发货的文字信息
const getPayBtnText = ()=>{
  if(props.orderInfo.type!==5) return "货款并发货"
  if(props.orderInfo.type===5&&props.orderInfo.payment === 2 || props.orderInfo.pay_state===1) return "收款"
  return "发货"
}

// 微信支付成功
const wxPaySucceed = ()=>{
  info.showPayWayPopup = false;
  info.showWxCode = false;
  emits('refresh')
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (__props.orderInfo.user_state == 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            ([0].includes(__props.orderInfo.pay_state)  && [2,6].includes(__props.orderInfo.type) && __props.orderInfo.payment == 1)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn_color",
                  onClick: _cache[0] || (_cache[0] = $event => (orderHandlerTip(0)))
                }, "支付定金"))
              : _createCommentVNode("", true),
            ([8].includes(__props.orderInfo.pay_state) && __props.orderInfo.state == 2 && __props.orderInfo.payment == 1 &&__props.orderInfo.pay_state!==6)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn_color red_btn",
                  onClick: _cache[1] || (_cache[1] = $event => (orderHandlerTip(4)))
                }, "我拒买"))
              : _createCommentVNode("", true),
            ([8].includes(__props.orderInfo.pay_state) && __props.orderInfo.state == 2 && __props.orderInfo.payment === 1 || [3].includes(__props.orderInfo.pay_state)&&__props.orderInfo.type===5)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "btn_color",
                  onClick: _cache[2] || (_cache[2] = $event => (orderHandlerTip(1)))
                }, "支付货款"))
              : _createCommentVNode("", true),
            ([8].includes(__props.orderInfo.pay_state) && __props.orderInfo.state == 2 && __props.orderInfo.payment === 2  || __props.orderInfo.pay_state===1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(__props.orderInfo.type===5&&__props.orderInfo.payment!==2?"等待商家发货":"已支付货款，等待商家确认"), 1),
                  ([8].includes(__props.orderInfo.pay_state) && __props.orderInfo.state == 2 && __props.orderInfo.type!==5 || __props.orderInfo.type===5&&__props.orderInfo.payment===2)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn_color",
                        onClick: _cache[3] || (_cache[3] = $event => (orderHandlerTip(1)))
                      }, "重新支付"))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              ((![5].includes(__props.orderInfo.type)&&__props.orderInfo.pay_state == 2 || [5].includes(__props.orderInfo.type))&& __props.orderInfo.state == 2&&__props.orderInfo.pay_state == 2)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn_color sign",
                    onClick: _cache[4] || (_cache[4] = $event => (orderHandlerTip(__props.orderInfo.type===5?3:9)))
                  }, "确认签收"))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            ([0].includes(__props.orderInfo.pay_state) && __props.orderInfo.payment == 1&& [4,5].includes(__props.orderInfo.type))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn_color",
                  onClick: _cache[5] || (_cache[5] = $event => (orderHandlerTip(0)))
                }, "支付定金"))
              : _createCommentVNode("", true),
            ([8].includes(__props.orderInfo.pay_state) && __props.orderInfo.state == 2&&__props.orderInfo.pay_state!==6)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn_color red_btn",
                  onClick: _cache[6] || (_cache[6] = $event => (orderHandlerTip(6)))
                }, "我拒卖"))
              : _createCommentVNode("", true),
            (__props.orderInfo.state == 2 && (__props.orderInfo.pay_state==8&&__props.orderInfo.payment==2 || __props.orderInfo.pay_state===1))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "btn_color",
                  onClick: _cache[7] || (_cache[7] = $event => (orderHandlerTip(__props.orderInfo.type===5&&(__props.orderInfo.payment == 2 || __props.orderInfo.pay_state===1)?9:2)))
                }, _toDisplayString("确认"+getPayBtnText()), 1))
              : _createCommentVNode("", true)
          ]))
    ]),
    _createVNode(OfflinePay, {
      showInfo: _unref(offlineShow),
      onClose: _cache[8] || (_cache[8] = $event => {_isRef(offlineShow) ? offlineShow.value = false : offlineShow = false;_unref(info).confirmLoading = false;}),
      orderInfo: __props.orderInfo,
      onRefresh: _cache[9] || (_cache[9] = $event => (wxPaySucceed()))
    }, null, 8, ["showInfo", "orderInfo"]),
    _createElementVNode("div", { innerHTML: zfbForm.value }, null, 8, _hoisted_7),
    _createVNode(PayWay, {
      money: _unref(info).payMoney,
      showPayWayPopup: _unref(info).showPayWayPopup,
      onGetPayType: getPayType,
      onClosePopup: _cache[10] || (_cache[10] = $event => (_unref(info).showPayWayPopup = false)),
      loading: _unref(info).confirmLoading,
      selectTypeList: _unref(info).payState === 1?[{id:8,text:'线下支付'}]:[{id:2,text:'个人保证金支付'},{id:1,text:'锁价保证金支付'},{id:3,text:'微信支付'},{id:6,text:'支付宝支付'}],
      defaultType: _unref(info).payState === 1?8:2
    }, null, 8, ["money", "showPayWayPopup", "loading", "selectTypeList", "defaultType"]),
    _createVNode(WxPayCode, {
      onSucceed: wxPaySucceed,
      money: _unref(info).payMoney,
      link: _unref(info).wxCodeLink,
      showCode: _unref(info).showWxCode,
      onClose: _cache[11] || (_cache[11] = $event => (_unref(info).showWxCode = false))
    }, null, 8, ["money", "link", "showCode"])
  ], 64))
}
}

}